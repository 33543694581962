import { useEffect } from "react";
import "./AboutPage.css";
import GetSeoData from "../../Helper/GetSeoData";
import banner from "../../assets/img/AboutImg/abt-2.jpg";
import InnerBanner from "../InnerBanner/InnerBanner";
export default function AboutPage() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <GetSeoData type="About Us" id={null} />
      <section className="section-spacing-x section-spacing-x-mobile pt-0 pb-0 px-0">
        <InnerBanner />
        <div className="container-fluid my-4 my-md-5">
          <div className="row mb-2">
            <div className="col-lg-3">
              <img src={banner} className="w-100 h-100" alt="" />
            </div>
            <div className="col-lg-9">
              <h1 className="main-heading mb-2 mt-4 mt-md-1">Our Story</h1>
              <p className="fw-bold">
                Introducing Kaawaii Cosmetics where imagination meets beauty!{" "}
                <br />
                Welcome to the Kaawaii family! Our vibrant, high-quality
                cosmetics are designed to spark creativity and joy in children.
                Every product we create is a celebration of the unique spirit of
                childhood. We are dedicated to ensuring that our products are
                made with care and safe ingredients, and are gentle on delicate
                skin.
              </p>
              <p>
                Founders Neha & Siddharth Kannan of Kaawaii Cosmetics by Pink
                Parrot Kids, a kids' perfume brand dedicated to creating fun,
                safe, and delightful fragrances for young minds and noses! Our
                mission is to help kids feel confident, expressive, and joyful,
                one spritz at a time. We believe that scent can evoke
                imagination, spark creativity, and create special memories. Our
                perfumes are designed to be gentle, age-appropriate, and
                parent-approved, so kids can feel like their best selves. I'm
                passionate about creating products that bring happiness to
                children both boys & girls and peace of mind to parents. Let's
                make scent-sational memories together!"
              </p>
            </div>
          </div>
        </div>
      </section>

    </>
  );
}
