import { Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import Notiflix from "notiflix";
import { useContext, useEffect, useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { IoCall, IoMail } from "react-icons/io5";
import { store } from "../../Helper/Context/Store";
import PostApiCall from "../../Helper/PostApi";
import "./ContactPage.css";
import { TfiReload } from "react-icons/tfi";
import GetSeoData from "../../Helper/GetSeoData";
import InnerBanner from "../InnerBanner/InnerBanner";

export default function ContactPage() {
  useEffect(() => {
    generateRandomCaptcha();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const { clientData } = useContext(store);
  const [captcha, setCaptcha] = useState("");
  const [enteredCaptcha, setEnteredCaptcha] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    email: "",
    message: "",
  });
  const handleRegexValidation = (email, phone) => {
    let result;
    if (email) {
      result = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    } else {
      result = /^\d{10}$/.test(phone);
    }
    return result;
  };
  const generateRandomCaptcha = () => {
    const newCaptcha = Math.random().toString(36).slice(2, 7).toUpperCase();
    setCaptcha(newCaptcha);
  };
  function isCaptchaValid(enteredCaptcha) {
    return captcha == enteredCaptcha;
  }
  const handleInputChange = (name, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  function handleValidateForm() {
    Notiflix.Loading.dots("Please wait...");

    if (formData.name === "") {
      Notiflix.Notify.failure("Name is required");
      Notiflix.Loading.remove();
      return;
    }
    if (formData.mobile === "") {
      Notiflix.Notify.failure("Phone number is required");
      Notiflix.Loading.remove();
      return;
    } else if (!handleRegexValidation("", formData.mobile)) {
      Notiflix.Notify.failure("Invalid phone number");
      Notiflix.Loading.remove();
      return;
    }
    if (formData.email == "") {
      Notiflix.Notify.failure("Email address is required");
      Notiflix.Loading.remove();
      return;
    } else if (!handleRegexValidation(formData.email, "")) {
      Notiflix.Notify.failure("Invalid email address");
      Notiflix.Loading.remove();
      return;
    }
    if (formData.message === "") {
      Notiflix.Notify.failure("Message is required");
      Notiflix.Loading.remove();
      return;
    }
    if (!isCaptchaValid(enteredCaptcha)) {
      Notiflix.Notify.failure("Invalid Captcha");
      Notiflix.Loading.remove();
      return;
    }
    onSubmit();
  }

  function clearFormData() {
    setFormData({
      name: "",
      mobile: "",
      email: "",
      message: "",
    });
  }

  function onSubmit() {
    PostApiCall.postRequest(
      {
        name: formData.name,
        mobile: formData.mobile,
        email: formData.email,
        message: formData.message,
      },
      "ContactUsMailer"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          Notiflix.Loading.remove();
          clearFormData();
          // generateRandomCaptcha();
          Notiflix.Notify.success("Form Submitted Successfully.");
        } else {
          Notiflix.Notify.failure("Something went wrong!");
        }
      })
    );
  }

  return (
    <>
      <GetSeoData type="Contact Us" id={null} />
      {/* <section className="section-spacing-x section-spacing-x-mobile py-4"> */}
      <section className="section-spacing-x section-spacing-x-mobile pt-0 pb-0 px-0">
        <InnerBanner />
        <div className="container-fluid mb-5">
          <h1 className="text-center main-heading mt-4">Get in Touch with Us!</h1>
          <div className="row flex-lg-row flex-column-reverse mt-3">
            <div className="col-lg-6">
              <h4 className="main-heading mb-0 mt-4 mt-md-1">Contact info</h4>
              <div className="contact-info-box">
                <div className="icon">
                  <FaLocationDot />
                </div>
                <div className="text-capitalize">
                  <p>Our Office</p>
                  <a
                    className="text-capitalize"
                    href="https://maps.app.goo.gl/VCsPZvJY66vXYb8fA"
                    target="_blank">
                    {/* B 7/1, near Rajouri Garden, Upper Ground,
                    <br />
                    Rajouri Garden, New Delhi, Delhi - 110 027, INDIA. */}
                    <strong>{clientData[0]?.companyname} ( {clientData[0]?.store} )</strong><br />
                    {clientData[0]?.address}
                  </a>
                </div>
              </div>
              <div className="contact-info-box">
                <div className="icon">
                  <IoCall />
                </div>
                <div>
                  <p>Call Us</p>
                  <a
                    // href={`tel:+91${clientData[0]?.mobile}`}
                    href="tel:+919967855616"
                  >
                    {/* {clientData[0]?.mobile} */}
                    +91 99678 55616
                  </a>
                </div>
              </div>
              <div className="contact-info-box">
                <div className="icon">
                  <FaWhatsapp />
                </div>
                <div>
                  <p>Message Us</p>
                  {/* <a href={`https://wa.me/+91${clientData[0]?.mobile}`}> */}
                  <a href="https://wa.me/+919967855616">
                    {/* {clientData[0]?.mobile} */}
                    +91 99678 55616
                  </a>
                </div>
              </div>
              <div className="contact-info-box">
                <div className="icon">
                  <IoMail />
                </div>
                <div>
                  <p>Mail Us</p>
                  {/* <a href="{`mailto:${clientData[0]?.email}`}"> */}
                  <a href="mailto:wecare@kaawaiicosmetics.com">
                    {/* {clientData[0]?.email} */}
                    wecare@kaawaiicosmetics.com
                  </a>
                </div>
              </div>
            </div>
            {/*  Contact form on the right side */}
            <div className="col-lg-6">
              <p className="mb-0 fs-4 text-dark">Welcome to <storng className="fw-bold">Kaawaii cosmetics!</storng></p>
              <p>
                We value your feedback, inquiries,
                and support. Whether you have questions about our products, need
                assistance with an order, or just want to say hello, we are here
                to help. Feel free to reach out to us by filling the form below:
              </p>
              <div className="row contact-form mt-3">
                <div className="col-lg-6 mb-4">
                  <Input
                    placeholder="Enter your name"
                    value={formData.name}
                    onChange={(e) => handleInputChange("name", e.target.value)}
                  />
                </div>
                <div className="col-lg-6 mb-4">
                  <Input
                    placeholder="Phone no"
                    value={formData.mobile}
                    onChange={(e) =>
                      handleInputChange("mobile", e.target.value)
                    }
                  />
                </div>
                <div className="col-12 mb-4">
                  <Input
                    placeholder="Email Address"
                    value={formData.email}
                    onChange={(e) => handleInputChange("email", e.target.value)}
                  />
                </div>
                <div className="col-12 mb-4">
                  <TextArea
                    rows={5}
                    placeholder="Message"
                    value={formData.message}
                    onChange={(e) =>
                      handleInputChange("message", e.target.value)
                    }
                  />
                </div>
                <div className="form-group col-lg-6 mb-4 pb-0">
                  <div className="captcha-box">
                    <input
                      type="text"
                      value={captcha}
                      disabled
                      className="form-control"
                    />
                    <div
                      className="captcha"
                      onClick={() => generateRandomCaptcha()}>
                      <TfiReload />
                    </div>
                  </div>
                </div>
                <div className="form-group col-lg-6 mb-4">
                  <input
                    type="text"
                    value={enteredCaptcha}
                    onChange={(e) =>
                      setEnteredCaptcha(e.target.value.toUpperCase())
                    }
                    className="form-control"
                    placeholder="Enter Captcha *"
                  />
                </div>
                <div className="col-12">
                  <button
                    className="theme-btn border-0"
                    onClick={handleValidateForm}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
