import React, { useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination } from "swiper/modules";
import TestimonialMobile from "../../assets/img/testi-mobile.png";
import Testimonial from "../../assets/img/testimonial.png";
import PostApiCall from "../../Helper/PostApi";
import TestiVideo from "../../assets/img/testimonial.mp4";
import "./TestimonialSection.css";
export default function TestimonialSection({ testimonialData }) {
  const [websiteTestimonialData, setWebsiteTestimonialData] = useState([]);
  useEffect(() => {
    GetTestimonial();
  }, []);
  const GetTestimonial = () => {
    PostApiCall.postRequest(
      {
        whereClause: "",
        recordCount: "*",
      },
      "GetTestimonial"
    ).then((results) => {
      results.json()?.then((obj) => {
        if (results.status === 200 || results.status === 201) {
          setWebsiteTestimonialData(obj.data);
        }
      });
    });
  };
  return (
    <div className="container-fluid testimonial-section">
      <img
        src={Testimonial}
        className="w-100 d-none d-lg-block"
        alt="Testimonial Kaawaii Cosmetics"
      />
      <img
        src={TestimonialMobile}
        className="w-100 d-block d-lg-none"
        alt="Testimonial Kaawaii Cosmetics"
      />
      <div className="container h-100">
        <div className="row h-100 align-items-center justify-content-center">
          <div className="col-lg-6 pt-5 mt-5 pe-lg-5 me-lg-5">
            <Swiper
              pagination={{
                dynamicBullets: true,
              }}
              // autoplay="flase"
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              slidesPerView={1}
              spaceBetween={20}
              loop={true}
              modules={[Autoplay, Pagination]}
              className="mySwiper pb-4 pb-lg-5 pe-lg-5">
              {websiteTestimonialData.length > 0
                ? websiteTestimonialData?.map((data) => {
                    return (
                      <>
                        <SwiperSlide>
                          <div className="testimonial-card">
                            <p>{data.fld_feedback}</p>
                            <span>{data.fld_name}</span>
                          </div>
                        </SwiperSlide>
                      </>
                    );
                  })
                : ""}
            </Swiper>
          </div>
          <div className="col-lg-2 col-5 pt-lg-5 mt-lg-5 me-lg-5">
            <div className="testimonial-video">
              <video loop muted autoPlay playsInline>
                <source src={TestiVideo} type="video/mp4" />
              </video>
              <p>#loveit</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
