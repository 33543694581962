import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import PostApiCall from "./PostApi";
export default function GetSeoData({ type, id }) {
  const [seoData, setSeoData] = useState([]);
  useEffect(() => {
    fetchSeoData();
  }, [window.location.pathname]);
  const fetchSeoData = () => {
    let whereclause =
      id == null
        ? `where fld_type = '${type}'`
        : `where fld_type = '${type}' and fld_id = ${id}`;
    PostApiCall.postRequest(
      {
        whereClause: whereclause,
      },
      "GetSEOData"
    ).then((results) =>
      results.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          setSeoData(obj1.data[0]);
        }
      })
    );
  };
  return (
    <div>
      <Helmet>
        <title>{seoData?.fld_meta_tile}</title>
        <meta name="description" content={seoData?.fld_meta_description} />
        <meta property="og:title" content={seoData?.fld_meta_tile} />
        <meta
          property="og:description"
          content={seoData?.fld_meta_description}
        />
        <meta property="og:image" content={seoData?.fld_og_image} />
        {/* <link rel="canonical" href="https://kaawaiicosmetics.com/" /> */}
        <link rel="canonical" href={seoData?.FLD_CANONICAL} />
      </Helmet>
    </div>
  );
}
