import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Breadcrumbs = ({ crumbs }) => {
	return (
		<Breadcrumb>
			{crumbs.map((crumb, index) => (
				<Breadcrumb.Item
					key={index}
					active={index === crumbs.length - 1}
					linkAs={index === crumbs.length - 1 ? 'span' : Link}
					linkProps={{ to: crumb.path }}
				>
					{crumb.name}
				</Breadcrumb.Item>
			))}
		</Breadcrumb>
	);
};

export default Breadcrumbs;
