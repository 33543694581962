import { createContext, useState } from "react";

export const store = createContext()

const Provider = ({
    children
}) => {
    const [logo, setLogo] = useState("");
    const [clientData, setClientData] = useState([]);
    const [vertical, setVertical] = useState([]);
    const [cart, setcart] = useState(0);
    const [cartamount, setcartamount] = useState(0);
    const [banner, setBanner] = useState([]);
    const [cartItems, setCartItems] = useState([]) // newly added to track the cart data
    const [cartData, setCartData] = useState([]); //for cart page

    const [showPincodeModal, setShowPincodeModal] = useState(false);
    const [pincode, setPincode] = useState(null);
    const [currentPage, setCurrentPage] = useState("Home");
    const [loggedIn, setLoggedIn] = useState(false);
    const [siteHeader, setSiteHeader] = useState([]);
    const [category4Data, setCategory4Data] = useState([]);
    const [category3Data, setCategory3Data] = useState([]);
    const [storeStatus, setStoreStatus] = useState("");
    const [totalOrders, setTotalOrders] = useState("");

    //  For product Card
    const [exclusiveOffers, setExclusiveOffers] = useState([]);

    return (
        <store.Provider value={{
            vertical, setVertical,
            cart, setcart, cartamount, setcartamount, currentPage, setCurrentPage, banner, setBanner, logo, setLogo,
            showPincodeModal, setShowPincodeModal,
            pincode, setPincode,
            clientData, setClientData,
            loggedIn, setLoggedIn,
            siteHeader,
            setSiteHeader,
            cartItems, setCartItems,
            category4Data, setCategory4Data,
            category3Data, setCategory3Data,
            cartData, setCartData,
            storeStatus, setStoreStatus,
            totalOrders, setTotalOrders,
            exclusiveOffers, setExclusiveOffers
        }}>
            {children}
        </store.Provider>
    )
}

export default Provider;