import { useContext } from "react";
import { MdCurrencyRupee } from "react-icons/md";
import { Link } from "react-router-dom";
import BlueMobile from "../../assets/img/All-Four-Product/BLUE-M.jpg";
import Blue from "../../assets/img/All-Four-Product/BLUE.jpg";
import OrangeMobile from "../../assets/img/All-Four-Product/ORANGE-M.jpg";
import Orange from "../../assets/img/All-Four-Product/ORANGE.jpg";
import PinkMobile from "../../assets/img/All-Four-Product/PINK-M.jpg";
import Pink from "../../assets/img/All-Four-Product/PINK.jpg";
import YellowMobile from "../../assets/img/All-Four-Product/YELLOW-M.jpg";
import Yellow from "../../assets/img/All-Four-Product/YELLOW.jpg";
import { store } from "../../Helper/Context/Store";
import AddToCart from "../AddToCart/AddToCart";
import AddToCartQty from "../AddToCart/AddToCartQty";
import "./AllFourProducts.css";
import { Skeleton } from "antd";
export default function AllFourProducts(props) {
  const { cartItems } = useContext(store);

  const Products = [
    {
      img: Pink,
      mobileimg: PinkMobile,
      style: "flower-main-box",
      addbtnstyle: "flower-add-btn",
      category: "Perfume",
      productname: "Flower Power",
    },
    {
      img: Orange,
      mobileimg: OrangeMobile,
      style: "orange-main-box",
      addbtnstyle: "orange-add-btn",
      category: "Perfume",
      productname: "Orange Blossom",
    },
    {
      img: Blue,
      mobileimg: BlueMobile,
      style: "aqua-main-box",
      addbtnstyle: "aqua-add-btn",
      category: "Perfume",
      productname: "Aqua Blue",
    },
    {
      img: Yellow,
      mobileimg: YellowMobile,
      style: "timer-main-box",
      addbtnstyle: "timber-add-btn",
      category: "Perfume",
      productname: "Timber Gold",
    },
  ];

  return (
    <>
      {props.loadingState === true ? (
        <>
          <div className="container-fluid product-section product-section-skeleton">
            <Skeleton.Image className="w-100 h-100 d-none d-lg-block" />
            <div className="row">
              <div className="col-12 d-block d-lg-none">
                <div className="four-product-card">
                  <div className="image mb-2 w-100">
                    <Skeleton.Image className="img-fluid w-100 h-100 aspect-ratio position-static" />
                  </div>
                  <div className="content">
                    <p className="price mb-0">
                      <Skeleton.Input />
                    </p>
                    {/* <button className={`btn ${bannerStyle}  default-btn-one cursor-pointer mx-0`}> */}

                    <span className="cursor-pointer">
                      <Skeleton.Button
                        classNames={`btn default-btn-one cursor-pointer mx-0`}
                      />
                    </span>
                    {/* </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        props.perfumeData.map((product) => {
          const isInCart = cartItems.find((item) => {
            if (item.fld_variantid === product.fld_variantid) {
              return item;
            }
            return false;
          });
          let banner;
          let mobilebanner;
          let bannerStyle;
          let productsbtncolor;
          let category;
          let productname;
          switch (product.fld_itemname) {
            case "Flower Power":
              banner = Products[0].img;
              mobilebanner = Products[0].mobileimg;
              bannerStyle = Products[0].style;
              productsbtncolor = Products[0].addbtnstyle;
              category = "Perfume";
              productname = "Flower Power";
              break;
            case "Orange Blossom ":
              banner = Products[1].img;
              mobilebanner = Products[1].mobileimg;
              bannerStyle = Products[1].style;
              productsbtncolor = Products[1].addbtnstyle;
              category = "Perfume";
              productname = "Orange Blossom";
              break;
            case "Aqua Blue":
              banner = Products[2].img;
              mobilebanner = Products[2].mobileimg;
              bannerStyle = Products[2].style;
              productsbtncolor = Products[2].addbtnstyle;
              category = "Perfume";
              productname = "Aqua Blue";
              break;
            case "Timber Gold":
              banner = Products[3].img;
              mobilebanner = Products[3].mobileimg;
              bannerStyle = Products[3].style;
              productsbtncolor = Products[3].addbtnstyle;
              category = "Perfume";
              productname = "Timber Gold";
              break;
          }

          return (
            <>
              <div className="container-fluid product-section">
                <Link
                  to={`/productdetail/${product.fld_variantname
                    ?.replace(/\s/g, "-")
                    .toLowerCase()}/${product.fld_variantid}`}>
                  <img
                    src={banner}
                    alt="product-img"
                    className="w-100 d-none d-lg-block"
                  />
                </Link>
                <div className="row">
                  <div className="col-12 d-block d-lg-none">
                    <div className="four-product-card">
                      <div className="image mb-2">
                        <Link
                          to={`/productdetail/${product.fld_variantname
                            ?.replace(/\s/g, "-")
                            .toLowerCase()}/${product.fld_variantid}`}>
                          <img
                            src={mobilebanner}
                            className="img-fluid position-static"
                            alt={product.fld_variantname}
                          />
                        </Link>
                      </div>
                      <div className="content">
                        <p className="price mb-0">
                          <MdCurrencyRupee />{" "}
                          {product.fld_website_discount_price}
                        </p>
                        {/* <button className={`btn ${bannerStyle}  default-btn-one cursor-pointer mx-0`}> */}
                        {isInCart ? (
                          <AddToCartQty
                            itemid={product.fld_variantid}
                            quantity={isInCart.fld_quantity}
                            cartlineid={isInCart.fld_cart_dtl_id}
                          />
                        ) : (
                          <span className="cursor-pointer">
                            <AddToCart
                              itemid={product.fld_variantid}
                              classNames={`btn ${productsbtncolor}  default-btn-one cursor-pointer mx-0`}
                            />
                          </span>
                        )}
                        {/* </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })
      )}
    </>
  );
}
