import React, { useState } from "react";
import "./GoToTop.css"
import { HiOutlineChevronUp } from "react-icons/hi";

const GoToTop = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 500) {  
      setVisible(true);
    } else if (scrolled <= 500) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
               in place of 'smooth' */
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <div
      style={{
        transition: "1s",
        transform: visible ? "translateY(50px)" : "translateY(-1000px)",
      }}
      className="go-to-top">
      <HiOutlineChevronUp onClick={scrollToTop} />
    </div>
  );
};

export default GoToTop;
