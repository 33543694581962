import React from "react";
import Homebannerimg from "../../assets/img/home-banner/home-banner.jpg";
import HomeMobileBanner from "../../assets/img/home-banner/home-mobile.jpg";
import "./HomeBanner.css";
import { HiMiniBars3CenterLeft } from "react-icons/hi2";
import Notiflix from "notiflix";
import { useContext, useEffect, useState } from "react";
import {
  Container,
  Dropdown,
  Nav,
  Navbar,
  NavDropdown,
  Offcanvas,
} from "react-bootstrap";
import { AiFillInstagram } from "react-icons/ai";
import { BiSolidUser } from "react-icons/bi";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaPinterestP,
  FaYoutube,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import { IoCall, IoSearch } from "react-icons/io5";
import { MdShoppingCart } from "react-icons/md";
import { Link, useHistory } from "react-router-dom";
import logo from "../../assets/img/Kaawaii-logo-black.png";
import logoold from "../../assets/img/Kaawaii-logo-black.png";
import { store } from "../../Helper/Context/Store";
import ChangePassword from "../Login/ChangePassword";
import LoginModal from "../Login/LoginModal";
import LogoutModal from "../Login/LogoutModal";
import SocialIcons from "../WrapperComponents/SocialIcons";
import { Form, InputGroup } from "react-bootstrap";
import "../MainNavabar/MainNavabar.css";
import Button from "react-bootstrap/Button";
import GetApiCall from "../../Helper/GetApi";

import amex from "../../assets/img/payements-icons/amex.svg";
import bhim from "../../assets/img/payements-icons/bhim.svg";
import gpay from "../../assets/img/payements-icons/gpay.svg";
import mastercard from "../../assets/img/payements-icons/mastercard.svg";
import paytm from "../../assets/img/payements-icons/paytm.svg";
import phonepe from "../../assets/img/payements-icons/phonepe.svg";
import rupay from "../../assets/img/payements-icons/rupay.svg";
import upi from "../../assets/img/payements-icons/upi.svg";
import visa from "../../assets/img/payements-icons/visa.svg";
import { Skeleton } from "antd";
export default function HomeBanner({ banners, loadingState }) {

  return (
    <>
      {
        loadingState === true ?
          <div className="contanier-fluid homebannersize homebannersize-skeleton p-0 m-0">
            <Skeleton.Image className="w-100 d-none d-lg-block" />

            <div className="mobile-imgae-space mobile-imgae-space-skeleton position-relative">
              <Skeleton.Image
                className="img-fluid d-block d-lg-none position-static h-100"
              />
            </div>
          </div> :
          <div className="contanier-fluid homebannersize p-0 m-0">
            <img src={Homebannerimg} className="w-100 d-none d-lg-block" alt="Kaawaii Cosmetics" />

            <div className="mobile-imgae-space position-relative">
              <img
                src={HomeMobileBanner}
                className="img-fluid d-block d-lg-none position-static"
                alt="Kaawaii Cosmetics"
              />
            </div>
            {/* <img src={Homebannerimg} className="w-100" /> */}
          </div>}
    </>
  );
}
