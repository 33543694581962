export default function SocialIcons({ link, target = "_blank", logo }) {

	return (
		<>
			{link && link !== null &&
				<li>
					<a href={link} target={target}>
						{logo}
					</a>
				</li>
			}
		</>
	)
}