import React, { useContext, useEffect, useState } from "react";
// import required modules
import PostApiCall from "../../Helper/PostApi";
import { store } from "../../Helper/Context/Store";
import "./HomeAbout.css";
import About from "../../assets/img/AboutImg/ABOUT.jpg";
import AboutMobile from "../../assets/img/AboutImg/ABOUT-MOBILE.jpg";
export default function HomeAbout() {
  const [vertical, setVertical] = useState([]);
  const [selectedVertical, setSelectedVertical] = useState("");
  const { storeStatus, cartItems } = useContext(store);
  useEffect(() => {
    GetProductVertical();
  }, []);
  const GetProductVertical = () => {
    PostApiCall.postRequest(
      {
        catCount: "*",
      },
      "GetProductVertical"
    ).then((results) =>
      results.json().then((obj1) => {
        if (results.status === 200 || results.status === 201) {
          setVertical(obj1.data);
        }
      })
    );
  };
  const handleSlideChange = (swiper) => {
    const currentSlideIndex = swiper.realIndex;
    if (vertical.length > currentSlideIndex) {
      setSelectedVertical(vertical[currentSlideIndex].fld_verticlename);
    }
  };
  return (
    // <section className="pt-2 mt-md-0 mt-4 pt-lg-4 pb-lg-2 about-bg-color">
    //   <div className="container-fluid container-md py-4">
    //     <div className="row">
    //       <div className="col-12 text-center">
    //         <h2 className="main-heading mb-0 pb-0 text-white">About Kaawaii</h2>
    //         <h3 className="main-subheading">Perfect Perfume</h3>
    //       </div>
    //       <div className="col-12 col-md-6 mt-md-0 mt-3 order-2 order-lg-1">
    //         <div className="p-md-3 px-0 w-100">
    //           <p className="text-white">
    //             Introducing Kaawaii Cosmetics where imagination meets beauty!{" "}
    //             <br />
    //             Welcome to the Kaawaii family! Our vibrant, high-quality
    //             cosmetics are designed to spark creativity and joy in children.
    //             Every product we create is a celebration of the unique spirit of
    //             childhood. We are dedicated to ensuring that our products are
    //             made with care and safe ingredients, and are gentle on delicate
    //             skin.
    //           </p>
    //         </div>
    //         <div className="px-md-3 px-0 w-100 ">
    //           <button className="p-2 common-btn">
    //             <a href="#"><i> Explore More </i></a>
    //           </button>
    //         </div>
    //       </div>
    //       <div className="col-12 col-md-6 mt-md-0 mt-3 order-1 order-lg-2">
    //         <div className="p-md-3 px-0 home-about-box">
    //           <img src={Aboutimg} className="w-100" />
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </section>
    <div className="container-fluid about-section">
      <img src={About} className="w-100 d-none d-lg-block" alt="About Us Kaawaii Cosmetics"/>
      <img src={AboutMobile} className="w-100 d-block d-lg-none" alt="About Us Kaawaii Cosmetics" />
      <div className="row">
        <div className="col-12"></div>
      </div>
    </div>
  );
}
