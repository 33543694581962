import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Skeleton, Space } from "antd";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import nodata from "../../assets/img/item-not-found.png";
import { store } from "../../Helper/Context/Store";
import GetSeoData from "../../Helper/GetSeoData";
import PostApiCall from "../../Helper/PostApi";
import Innerbanner from "../InnerBanner/InnerBanner";
import ProductCard from "../ProductCard/ProductCard";
import "./ProductListing.css";
export default function ProductListing() {
  const params = useParams();
  const [apiData, setApiData] = useState([]);
  const [productClass, setProductClass] = useState("col-6 col-lg-3 mb-4");
  const [dataLoading, setDataLoading] = useState(true);
  const { cartItems } = useContext(store);
  useEffect(() => {
    getItems();
  }, [params.categoryid]);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const getItems = () => {
    PostApiCall.postRequest(
      {
        verticalid: parseInt(params.categoryid),
        stateid: null,
      },
      "Get_All_Items"
    ).then((results) =>
      results.json().then((obj1) => {
        if (results.status === 200 || results.status === 201) {
          setApiData(obj1.data);
          setDataLoading(false);
        }
      })
    );
  };
  const items = [
    {
      label: "Price, High to Low",
      key: "1",
      value: "Price, High to Low",
    },
    {
      label: "Price, Low to High",
      key: "2",
      value: "Price, Low to High",
    },
  ];
  const handleMenuClick = (value) => {
    const sortedproducts = apiData;
    if (value == "Price, High to Low") {
      sortedproducts.sort((a, b) => {
        return (
          parseInt(b.fld_website_discount_price) -
          parseInt(a.fld_website_discount_price)
        );
      });
    } else if (value == "Price, Low to High") {
      sortedproducts.sort((a, b) => {
        return (
          parseInt(a.fld_website_discount_price) -
          parseInt(b.fld_website_discount_price)
        );
      });
    }
    setApiData(sortedproducts);
  };
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };
  return (
    <>
      <GetSeoData type="Category" id={params.categoryid} />
      <Innerbanner />
      <section className="section-spacing-x section-spacing-x-mobile mt-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h1 className="main-heading text-center mb-lg-0">
                {params.categoryid == 1 ? "Kaawaii Perfumes" : "Kaawaii Hamper Bags"}</h1>
              <div className="d-flex justify-content-end align-items-center mb-4">
                <div className="card-layout-cahane-box d-none d-lg-flex me-4">
                  <ul onClick={() => setProductClass("col-lg-4 col-12 mb-4")}>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                  </ul>
                  <ul onClick={() => setProductClass("col-lg-3 col-6 col-12 mb-4")}>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                  </ul>
                </div>
                <Dropdown menu={menuProps}>
                  <Button>
                    <Space>
                      Sort by
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </div>
            </div>

            {apiData.length ? (
              apiData
                .filter((dat) => dat.fld_status == "Active" && (dat.fld_verticalid == 1 || dat.fld_verticalid == 3))
                .map((val) => {
                  const isInCart = cartItems.find(
                    (item) => item.fld_variantid === val.fld_variantid
                  );
                  return (
                    <div key={val.fld_variantid} className={`${productClass}`}>
                      <ProductCard productData={val} isInCart={isInCart} />
                    </div>
                  );
                })
            ) : dataLoading === false ? (
              <div className="d-flex justify-content-center align-items-center w-100 listingpage-height blank-section nodata flex-column">
                <img className="mb-3 img-fluid" src={nodata} alt="No Data" />
                <p className="fs-5">The items you are searching for are not available.</p>
              </div>
            ) : null}

            {dataLoading === true && (
              <div className="col-12">
                <div className="row blank-section product-listing-skeleton mb-4">
                  <div className="col-6 col-md-12 col-lg-3">
                    <div className="product-box d-flex flex-column">
                      <Skeleton.Image className="w-100" />
                      <Skeleton.Button className="w-75 mt-2" />
                      <Skeleton.Button className="w-100 mt-2" />
                    </div>
                  </div>
                  <div className="col-6 col-md-12 col-lg-3">
                    <div className="product-box d-flex flex-column">
                      <Skeleton.Image className="w-100" />
                      <Skeleton.Button className="w-75 mt-2" />
                      <Skeleton.Button className="w-100 mt-2" />
                    </div>
                  </div>
                  <div className="item col-lg-3 d-none d-lg-block">
                    <div className="product-box d-flex flex-column">
                      <Skeleton.Image className="w-100" />
                      <Skeleton.Button className="w-75 mt-2" />
                      <Skeleton.Button className="w-100 mt-2" />
                    </div>
                  </div>
                  <div className="item col-lg-3 d-none d-lg-block">
                    <div className="product-box d-flex flex-column">
                      <Skeleton.Image className="w-100" />
                      <Skeleton.Button className="w-75 mt-2" />
                      <Skeleton.Button className="w-100 mt-2" />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
